import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const LocationService = {
    findLocation(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            // { query: 'supOrgId', val: payload.supplier_id },
            { query: 'supplieraddress1', val: payload.supplieraddress1 ? encodeURIComponent(payload.supplieraddress1) : null},
            { query: 'supplieraddress2', val: payload.supplieraddress2 ? encodeURIComponent(payload.supplieraddress2) : null},
            { query: 'suppliercity', val: payload.suppliercity ? encodeURIComponent(payload.suppliercity) : null},
            { query: 'supplierstate', val: payload.supplierstate ? encodeURIComponent(payload.supplierstate) : null},
            { query: 'provideraddress1', val: payload.provideraddress1 ? encodeURIComponent(payload.provideraddress1) : null},
            { query: 'provideraddress2', val: payload.provideraddress2 ? encodeURIComponent(payload.provideraddress2) : null},
            { query: 'providercity', val: payload.providercity ? encodeURIComponent(payload.providercity) : null},
            { query: 'providerstate', val: payload.providerstate ? encodeURIComponent(payload.providerstate) : null},
            { query: 'suporgname', val: payload.suporgname ? encodeURIComponent(payload.suporgname) : null},
            { query: 'locationname', val: payload.locationname ? encodeURIComponent(payload.locationname) : null},
            { query: 'supplierzipcode', val: payload.supplierzipcode ? encodeURIComponent(payload.supplierzipcode) : null},
            { query: 'providerzipcode', val: payload.providerzipcode ? encodeURIComponent(payload.providerzipcode) : null},
            { query: 'status', val: payload.status  ? encodeURIComponent(payload.status) : null},
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/provider/locations${param(arr)}`).then(resp => {
            return resp
        })
    },
    getCentralizedIntakeData() {
        return http.get(`${API_ROOT}/provider/locations/centralized-intake-data`).then(resp => {
            return resp
        })
    },
    getLocation(payload) {
        return http.get(`${API_ROOT}/provider/locations/${payload.location_id}?supOrgId=${payload.supplier_org_id}`).then(resp => {
            return resp
        })
    },
    updateLocation(payload) {
        return http.put(`${API_ROOT}/provider/locations/${payload.location_id}`, payload).then(resp => {
            return resp
        })
    },
    createLocation(payload) {
        return http.post(`${API_ROOT}/provider/locations`, payload).then(resp => {
            return resp
        })
    },
    updateVerifyLocation(payload) {
        return http.put(`${API_ROOT}/provider/locations/verify`, payload).then(resp => {
            return resp
        })
    },
    getSearchMeta() {
        return http.get(`${API_ROOT}/provider/locations/search-meta`).then(resp => {
            return resp
        })
    },
    getSupplierMeta() {
        return http.get(`${API_ROOT}/provider/locations/supplier-meta`).then(resp => {
            return resp
        })
    }
}

export default LocationService
